import AuthService from '../../services/AuthService'
import router from '../../router'
import { encrypt, decrypt } from './encrypt'

const state = () => ({
    isAuthenticated: false,
    loadingUser: false,
    redirect: '',
    token: localStorage.getItem('token') || null,
    user: (() => {
        try {
            return localStorage.getItem('user') ? decrypt(localStorage.getItem('user')) : null;
        } catch (error) {
            localStorage.removeItem('user');
            return null;
        }
    })(),
    userRegister: null,
    register: JSON.parse(localStorage.getItem('register')) || {
        pessoais: null,
        especificos: null,
        enderecos: null
    },
    registerSteps: localStorage.getItem('registerSteps') || 1
})
    
const mutations = {
    REDIRECT: (state, payload) => {
        state.redirect = payload;
    },
    SET_USER: (state, payload) => {
        const encryptedUser = encrypt(payload.user);
        localStorage.setItem('user', encryptedUser);
        localStorage.setItem('token', payload.token)
        state.isAuthenticated = true;
        state.token = payload.token
        state.user = payload.user
        if(router.currentRoute.value.path === '/auth' && !state.redirect){
            router.push({path: `/profile/settings`});
        }else {
            router.push({path: state.redirect});
            state.redirect = null;
        }
    },
    AUTH_USER: (state, payload) => {
        const encryptedUser = encrypt(payload.user);
        localStorage.setItem('user', encryptedUser);
        state.user = payload.user;
    },
    SET_LOADING_USER: (state, payload) => {
        state.loadingUser = payload
    },
    LOG_USER_OUT(state){
        if(router.currentRoute.value.path != '/auth') state.redirect = router.currentRoute.value.path;
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('cartItems');
        localStorage.removeItem('likedItems');
        localStorage.removeItem('notifications');
        localStorage.removeItem('municipios');
        localStorage.removeItem('n_Anuidade');

        state.orders = [];
        state.user = null;
        state.token = null;
        state.cart = [];
        state.liked = [];
        state.notifications = [];
        state.cidades = [];
        state.currentSteps = [];
        state.isAuthenticated = false;
        state.products = [];
        state.product = null;

        if(router.currentRoute.path !== '/auth'){
            router.push({path: `/auth`});
        }
    },
    REDEFINIR_SENHA(state){
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('cartItems');
        localStorage.removeItem('likedItems');
        localStorage.removeItem('notifications');
        localStorage.removeItem('municipios');

        state.orders = [];
        state.user = null;
        state.token = null;
        state.cart = [];
        state.liked = [];
        state.notifications = [];
        state.cidades = [];
        state.currentSteps = [];
        state.isAuthenticated = false;
        state.products = [];
        state.product = null;

        router.push({path: `/password-reset`});
    },
    SET_USER_PROFILE_IMAGE(state, imageUrl) {
        state.user.image = imageUrl;
    },
    SET_USER_REGISTER(state, data) {
        state.userRegister = data;
    },
    LIMPAR_REGISTER(state) {
        localStorage.removeItem('register');
        localStorage.removeItem('registerSteps');

        state.userRegister = null;
        state.registerSteps = 1;
        state.register = {
            pessoais: null,
            especificos: null,
            enderecos: null
        };
    },
    SET_DADOS_PESSOAIS(state, data) {
        state.register.pessoais = data;
        localStorage.setItem('register', JSON.stringify(state.register));
    },
    SET_DADOS_ESPECIFICOS(state, data) {
        state.register.especificos = data;
        localStorage.setItem('register', JSON.stringify(state.register));
    },
    SET_DADOS_ENDERECO(state, data) {
        state.register.enderecos = data;
        localStorage.setItem('register', JSON.stringify(state.register));
    },
    SET_STAGE_REGISTER(state, etapa) {
        state.registerSteps= etapa;
        localStorage.setItem('registerSteps', JSON.stringify(etapa));
    },
    SET_BACK_STAGE_REGISTER(state) {
        state.registerSteps = --state.registerSteps;
    }
}

const actions = {
    async loginUser({commit}, credentials ){
        try {
            commit('SET_LOADING_USER', true);
            const response = await AuthService.login(credentials);
            commit('SET_USER', response.data);
            commit('SET_LOADING_USER', false);
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
   
    async registerUser({commit, state}, files){
        try{
            commit('SET_LOADING_USER', true);
            const formData = new FormData();
    
            formData.append('cpf', files.cpf);
            formData.append('diploma', files.diploma);
            formData.append('entidadeClasse', files.entidadeClasse);
            formData.append('dadosFormularios', JSON.stringify(state.register));
        
            const response = await AuthService.create(formData);
            commit('SET_USER_REGISTER', response.data.user);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_STAGE_REGISTER', 5);
            commit('SET_LOADING_USER', false);
            return true;
        }catch(error){
            commit('SET_LOADING_USER', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
            return false;
        }
    },
    async registerDadosPessoais({commit}, { data }){
        try{
            commit('SET_LOADING_USER', true);
            commit('SET_DADOS_PESSOAIS', data);
            commit('SET_STAGE_REGISTER', 2);
            commit('SET_LOADING_USER', false);
        }catch(error){
            commit('SET_LOADING_USER', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async registerDadosEspecificos({commit}, { data }){
        try{
            commit('SET_LOADING_USER', true);
            commit('SET_DADOS_ESPECIFICOS', data);
            commit('SET_STAGE_REGISTER', 3);
            commit('SET_LOADING_USER', false);
        }catch(error){
            commit('SET_LOADING_USER', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async registerDadosEndereco({commit}, { data }){
        try{
            commit('SET_LOADING_USER', true);
            commit('SET_DADOS_ENDERECO', data);
            commit('SET_STAGE_REGISTER', 4);
            commit('SET_LOADING_USER', false);
        }catch(error){
            commit('SET_LOADING_USER', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchUser({state, commit}){
        try{
            commit('SET_LOADING_USER', true)
            const response = await AuthService.getUser(state.user.id);
            commit('AUTH_USER', response.data)
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updateBusinessUser({commit}, data){
        try {
            commit('SET_LOADING_USER', true)
            const response = await AuthService.updateBusiness(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false)
            // commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updatePhoneUser({commit, state}){
        try {
            commit('SET_LOADING_USER', true)
            const response = await AuthService.updatePhones(state.user.TelefoneSocios);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false)
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async verifyUser({state, commit}){
        try{
            if (state.user == null) return false
            if (state.user.order_status == 'Inadimplente') {
                commit('SET_ALERT', {
                    heading: 'Erro',
                    message: "Existem pendências em seu cadastro. Para regularizá-las, entre em contato com a Secretaria da SBU"
                });
                return false
            } else{
                return true;
            }
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getUser: state => state.user,
    getUserRegister: state => state.userRegister,
    getLoadingUser: state => state.loadingUser,
    getRegisterSteps: state => state.registerSteps,
    getDataRegisters: state => state.register
}

export default {
    state,
    getters,
    mutations,
    actions
};